<template lang="pug">
.silk-ribbon(
  v-if="title && sold === 0"
  v-bind:style="{ background: color, top:top+'px', 'font-size': size+'rem'}"
  v-text="title"
)
</template>
<script>
export default {
  name: 'Ribbon',
  props: {
    sold: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: ""
    },
    color:{
      type: String,
      default: ""
    },
    top:{
      type:Number,
      default:30
    },
    size: {
      type:Number,
      default:1
    }
  }
}
</script>
<style lang="scss">
  .silk-ribbon{
    background:#00B3ED;
    border-radius: 0 3px 3px 0;
    box-shadow:-1px 2px 4px rgba(0,0,0,0.5);
    color:white;
    font-weight: 600;
    padding:8px 15px;
    position:absolute;
    text-shadow: 1px 1px 5px rgba(0,0,0,0.5);
    top:30px;
    width: fit-content;
    z-index: 2;
    &:before,
    &:after {
      position:absolute;
      content:"";
      display:block;
    }
    &:before {
      width:7px;
      height:120%;
      padding:0 0 7px;
      top:0;
      left:-6px;
      background:inherit;
      border-radius:5px 0 0 5px;
    }
    &:after {
      width:5px;
      height:5px;
      background:rgba(0,0,0,0.35);
      bottom:-5px;
      left:-5px;
      border-radius:5px 0 0 5px;
    }
  }
</style>